import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				ARTPOTTERY
			</title>
			<meta name={"description"} content={"ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи."} />
			<meta property={"og:title"} content={"ARTPOTTERY"} />
			<meta property={"og:description"} content={"ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
		</Components.Header12>
		<Section background="--color-dark" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Бажаєте спробувати себе в кераміці?
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					У ArtPottery ми прагнемо допомогти вам втілити ваші творчі бачення в життя. Приєднуйтесь до нас сьогодні та побачте, куди може завести ваша креативність.
					<br />
					<br />
					Щоб дізнатися про наші послуги та як ми можемо допомогти вам на гончарному шляху, зв'яжіться з нами. Наша команда готова відповісти на ваші запитання та допомогти обрати ідеальний клас або майстер-клас, який відповідає вашим потребам. Завітайте до нас і дізнайтеся більше про все, що ми можемо запропонувати.
				</Text>
				<Link
					href="tel:1234567899"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					Pushkinska St, 19, Zhytomyr, Zhytomyr Oblast, 10014
					<br />
					<br />
					0412473259
				</Link>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link3">
				Pushkinska St, 19, Zhytomyr,{" "}
				<br />
				Zhytomyr Oblast, 10014
				<br />
				<br />
				0412473259
			</Override>
		</Components.Footer12>
		<Components.Policy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});